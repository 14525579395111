import { TranslatedString } from './no';

const seJSON: { [key in TranslatedString]: string } = {
  // Languages
  'Language Switcher': 'Språkväxling',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svenska',
  'Total amount (Including Tax)': 'Totalt belopp (inklusive skatt)',

  'Go back': 'Gå tillbaka',
  'Pay with card': 'Betala med kort',
  'Choose Payment Method': 'Välj betalsätt',

  Start: 'Start',
  Continue: 'Fortsätta',

  'Registration number': 'Registreringsnummer',
  Submit: 'Skicka',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Skicka recept',
  Home: 'Hem',

  'All set to go': 'Allt klart att köra',
  'Payment failed': 'Betalning misslyckades',

  'License plate': 'Registreringsskylt',

  'Total cost': 'Total kostnad',
  'Try again': 'Försök igen',

  'Parking area': 'Parkeringsplats',
  Duration: 'Varighet',
  'Invoice date': 'Fakturadatum',
  Amount: 'Belopp',

  'Access terminal': 'Åtkomstterminal',

  Security: 'säkerhet',
  'Enter your registration number': 'Ange ditt registreringsnummer',
  Next: 'Nästa',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det finns ingen parkering på {{RegistrationNumber}}. Är du säker på att du har skrivit rätt?',

  'Gate successfully opened': 'Porten har öppnats',
  'Gate cannot be opened': 'Porten kan inte öppnas',

  'Cannot open gate for the given license plate':
    'Kan inte öppna grinden för den givna registreringsskylten',
  'Parking terminal': 'Parkeringsautomat',
  'We did not find anything on this license plate number. Double-check':
    'Vi hittade inget på detta registreringsnummer. Dubbelkolla',
  'Your parking has been validated. You can now exit the parking.':
    'Din parkering har validerats. Du kan nu lämna parkeringen.',
  'We found more than one match for this license plate. Write complete license plate number or check with reception':
    'Vi hittade mer än en match för detta registreringsnummer. Skriv hela registreringsnumret eller kolla med receptionen',
  'An error occurred while processing your request':
    'Ett fel inträffade vid behandlingen av din begäran',
};

export default seJSON;
